.about-top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    &-img {
        display: block;
        position: relative;
        z-index: 1;
        img {
            display: block;
        }
    }
    &-info {
        flex: 1;
        position: relative;
        z-index: 2;
        background-color: #fff;
        margin-left: -70px;
        height: 370px;
        font-size: 14px;
        line-height: 32px;
        color: #777;
        box-sizing: border-box;
        padding: 60px 80px 0;
        @include articleReset();
        .title {
            font-size: 30px;
            color: $mainColor;
            margin-bottom: 15px;
        }
        .p {
            font-size: 14px;
            line-height: 32px;
            height: 32px * 6;
            overflow: hidden;
            color: #777;
        }
        .more {
            display: block;
            position: absolute;
            width: 84px;
            height: 84px;
            text-align: center;
            line-height: 84px;
            color: #fff;
            background-color: $mainColor;
            font-size: 14px;
            text-transform: uppercase;
            transition: all .2s ease;
            bottom: 0;
            left: -42px;
            &:hover {
                background-color: $hoverColor;
            }
        }
    }
}
.about-bottom {
    display: flex;
    padding-top: 20px;
    .right {
        width: (325/1348) * 100%;
        .item {
            width: 100%;
        }
        .img {
            padding-bottom: (499/296) * 100%;
            @media screen and (max-width: 1366px) {
                padding-bottom: (502/296) * 100%;
            }
        }
    }
    .left {
        flex: 1;
        //overflow: hidden;
        font-size: 0;
        .item {
            width: 33.33%;
            padding-right: 15px;
        }
    }
    .item {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        margin-bottom: 15px;
    }
    .wrapper {
        display: block;
        padding: 15px;
        background-color: #fff;
        transition: all .4s ease;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        }
    }
    .img {
        width: 100%;
        position: relative;
        padding-bottom: (185/296) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    .info {
        height: 82px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 24px;
    }
    .t {
        font-size: 24px;
        line-height: 34px;
        color: $mainColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .en {
        font-family: Arial;
        font-size: 12px;
        color: #777;
        text-transform: uppercase;
    }
}