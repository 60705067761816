.news-list-wrap {
    width: 100%;
    .hd {
        position: relative;
        margin-bottom: 65px;
        .shadow {
            position: absolute;
            bottom: -30px;
            left: 0;
            width: 100%;
            text-align: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .swiper-container {
            padding: 40px 60px 40px 40px;
            background-color: #fff;
        }
        .swiper-slide {
            display: flex;
            &:hover {
                .img-box img {
                    transform: scale(1.05);
                }
                .info-box .title {
                    color: $hoverColor;
                }
            }
        }
        .img-box {
            width: 335px;
            margin-right: 50px;
            .img {
                display: block;
                position: relative;
                height: 0;
                padding-top: (230/335) * 100%;
                backface-visibility: hidden;
                overflow: hidden;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include transition(.4s);
            }
        }
        .info-box {
            flex: 1;
            width: 0;
            padding-top: 20px;
            overflow: hidden;
            .title {
                width: 100%;
                margin-bottom: 25px;
                line-height: 1;
                font-size: 22px;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                @include transition(.4s);
            }
            .msg {
                height: 84px;
                line-height: 28px;
                font-size: 13px;
                color: #777;
                overflow: hidden;
            }
        }
        .swiper-pagination {
            position: absolute;
            left: 430px;
            bottom: 85px;
            width: auto;
            text-align: left;
            font-size: 0;
        }
        .my-bullet {
            display: inline-block;
            vertical-align: top;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            margin: 0 3px;
            font-size: 12px;
            color: #fff;
            background-color: #dbdbdb;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            @include transition(.4s);
        }
        .my-bullet-active {
            background-color: $hoverColor;
        }
    }
    .bd {
        display: flex;
        flex-wrap: wrap;
        margin-left: -60px;
        margin-right: -60px;
        @media screen and (max-width: 1500px) {
            margin-left: -30px;
            margin-right: -30px;
        }
        li {
            width: 50%;
        }
        .item {
            display: flex;
            align-items: center;
            padding-bottom: 45px;
            margin: 0 60px 50px;
            border-bottom: 1px dashed #b7b7b7;
            @media screen and (max-width: 1500px) {
                margin: 0 30px 50px;
            }
            &:hover {
                .msg-box {
                    .tt {
                        color: $hoverColor;
                    }
                }
            }
        }
        .img-box {
            width: 200px;
            .img {
                position: relative;
                height: 0;
                padding-top: (150/200) * 100%;
                overflow: hidden;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .msg-box {
            flex: 1;
            width: 0;
            padding-left: 18px;
            overflow: hidden;
        }
        .tt {
            width: 100%;
            margin-bottom: 20px;
            line-height: 1;
            font-size: 18px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(.4s);
        }
        .p {
            height: 72px;
            line-height: 24px;
            font-size: 14px;
            color: #888;
            overflow: hidden;
        }
    }
}
.recruitment-list-wrap {
    width: 100%;
    .lists-box {
        width: 100%;
    }
    .item {
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        &.on {
            .list-title-box {
                color: #fff;
                background: $mainColor;
                .icon {
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
        }
    }
    .list-title-box {
        position: relative;
        display: flex;
        align-items: center;
        height: 55px;
        padding-left: 93px;
        font-size: 14px;
        color: #666;
        background-color: #fff;
        cursor: pointer;
        @include transition(.4s);
        .name {
            min-width: 147px;
        }
        .num {
            width: 122px;
            padding-left: 28px;
        }
        .address {
            width: 212px;
            padding-left: 55px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .date {
            width: 156px;
            padding-left: 70px;
        }
        .icon {
            position: absolute;
            top: 50%;
            right: 25px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #fff;
            border: 1px solid #787c81;
            border-radius: 50%;
            transform: translate(0, -50%);
            @include transition(.4s);
        }
        .iconfont {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #787c81;
        }
    }
    .list-con-box {
        display: none;
        padding: 50px 0;
        > div {
            display: flex;
            padding: 0 93px;
        }
        .box {
            width: 50%;
            box-sizing: border-box;
            &:nth-child(2) {
                padding-left: 45px;
                border-left: 1px dashed rgba(0, 0, 0, .5);
            }
        }
        .title {
            margin-bottom: 25px;
            line-height: 1;
            font-size: 22px;
            color: #444;
        }
        .p {
            margin-bottom: 10px;
            font-size: 13px;
            color: #555;
            &.deep-orange {
                color: $mainColor;
            }
        }
    }
}
.magazine-list-wrap {
    display: flex;
    width: 100%;
    .left {
        display: block;
        width: 496px;
        .wrapper {
            padding: 63px 30px 0;
            background-color: #fff;
            box-sizing: border-box;
            width: 100%;
            display: block;
            &:hover {
                .img-box {
                    opacity: .8;
                }
                .tt {
                    color: $hoverColor;
                }
            }
        }
        .img-box {
            position: relative;
            width: 100%;
            height: 557px;
            overflow: hidden;
            transition: opacity .2s ease;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .tt {
            text-align: center;
            font-size: 18px;
            color: #333;
            line-height: 60px;
            height: 60px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color .2s ease;
        }
    }
    .right {
        flex: 1;
        overflow: hidden;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            box-sizing: border-box;
            padding-left: 5px;
            margin-bottom: 5px;
        }
        .wrapper {
            display: block;
            box-sizing: border-box;
            background-color: #fff;
            padding: 22px 26px 0;
            &:hover {
                .img-box {
                    opacity: .8;
                }
                .tt {
                    color: $hoverColor;
                }
            }
        }
        .img-box {
            display: block;
            width: 100%;
            height: 265px;
            position: relative;
            transition: opacity .2s ease;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .tt {
            line-height: 50px;
            height: 50px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #333;
            transition: color .2s ease;
        }
    }
}
.product-menu {
    text-align: center;
    background-color: $mainColor;
    font-size: 0;
    .item {
        display: inline-block;
        vertical-align: top;
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border-right: 1px solid rgba(255, 255, 255, .2);
        transition: all .2s ease;
        &.on,
        &:hover {
            background-color: $hoverColor;
            color: #fff;
        }
        &:first-child {
            border-left: 1px solid rgba(255, 255, 255, .2);
        }
    }
}
.product-list-con {
    padding: 40px 20px;
    background-color: #f4f4f4;
}
.product-tag-con {
    padding-top: 40px;
    a {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
        font-size: 15px;
        color: #666;
        transition: all .2s ease;
        &:hover,
        &.on {
            text-decoration: underline;
            color: $hoverColor;
        }
    }
}
.product-list {
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        margin-top: 30px;
        &:nth-child(3n+1) {
            padding-right: 22px;
        }
        &:nth-child(3n+2) {
            padding-right: 11px;
            padding-left: 11px;
        }
        &:nth-child(3n+3) {
            padding-left: 22px;
        }
        a {
            display: block;
            &:hover {
                .img {
                    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
                    span {
                        img {
                            transform: scale(1.1, 1.1);
                        }
                    }
                }
                .t {
                    color: $hoverColor;
                }
            }
        }
    }
    .img {
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        transition: all .2s ease;
        span {
            display: block;
            width: 100%;
            padding-bottom: (266/405) * 100%;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                height: 100%;
                transition: all .4s ease;
            }
        }
    }
    .t {
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 18px;
        line-height: 26px;
        color: #333;
        transition: color .2s ease;
    }
    .p {
        font-size: 14px;
        line-height: 26px;
        color: #888;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        margin-bottom: 15px;
    }
    .views {
        text-align: center;
        font-size: 12px;
        color: #777;
        line-height: 20px;
        height: 20px;
        &:before {
            font-family: 'iconfont';
            content: '\e64c';
            font-size: 16px;
            display: inline-block;
            vertical-align: top;
            margin-right: 3px;
            line-height: 20px;
        }
    }
}
.product-group {
    padding-top: 40px;
    .hd {
        display: flex;
        margin-bottom: 35px;
    }
    .left {
        width: 826px;
        .swiper-slide {
            height: 0;
            padding-top: (468/826) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .swiper-pagination {
            left: 0;
            bottom: 20px;
            width: 100%;
            padding: 0 36px;
            text-align: right;
            box-sizing: border-box;
        }
        .my-bullet {
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            margin: 0 5px;
            background: #f5f5f5;
            border-radius: 50%;
            transition: all .2s ease;
        }
        .my-bullet-active {
            background: $mainColor;
        }
    }
    .right {
        flex: 1;
        padding: 100px 80px 80px;
        background: #fff;
        overflow: hidden;
        .title {
            position: relative;
            margin-bottom: 15px;
            padding-bottom: 20px;
            line-height: 1;
            font-size: 30px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 25px;
                height: 3px;
                background: $mainColor;
            }
        }
        .msg {
            height: 90px;
            line-height: 30px;
            font-size: 15px;
            color: #777;
            overflow: hidden;
        }
        .read {
            padding-top: 40px;
            font-size: 12px;
            color: #777;
            i {
                display: inline-block;
                vertical-align: middle;
                color: #777;
            }
        }
        .more-btn {
            display: block;
            width: 170px;
            height: 40px;
            margin-top: 25px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background: $mainColor;
            @include transition();
            &:hover {
                background: $hoverColor;
            }
        }
    }
    .bd {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
        li {
            width: calc-percent(1, 4);
        }
        .item {
            display: block;
            padding: 8px;
            margin: 0 7px 14px;
            background: #fff;
            transition: all .4s ease;
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                .img-box img {
                    transform: scale(1.05);
                }
                .tt {
                    color: $mainColor;
                }
            }
        }
        .img-box {
            position: relative;
            height: 0;
            padding-top: (200/312) * 100%;
            backface-visibility: hidden;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include transition(.4s);
            }
        }
        .info-box {
            height: 96px;
            padding: 28px 10px 20px;
            box-sizing: border-box;
            .title {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            .tt {
                flex: 1;
                line-height: 1;
                font-size: 16px;
                color: #666;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @include transition(.4s);
            }
            .read {
                font-size: 12px;
                color: #777;
                i {
                    display: inline-block;
                    vertical-align: middle;
                    color: #777;
                }
            }
            .p {
                font-size: 14px;
                color: #999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
.choose-buy-nav {
    display: flex;
    align-items: center;
    height: 65px;
    margin-bottom: 40px;
    padding: 0 85px;
    background-color: #fff;
    .list {
        padding-right: 45px;
        a {
            display: flex;
            align-items: center;
            &.active,
            &:hover {
                .round {
                    border-color: $hoverColor;
                    &::after {
                        visibility: visible;
                        @include touming(100);
                    }
                }
                .word {
                    color: $hoverColor;
                }
            }
        }
        .round {
            position: relative;
            width: 14px;
            height: 14px;
            border: 1px solid #c5c5c5;
            border-radius: 50%;
            @include transition();
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 6px;
                height: 6px;
                background-color: $hoverColor;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
                @include touming(0);
                @include transition();
            }
        }
        .word {
            padding-left: 10px;
            line-height: 1;
            font-size: 14px;
            color: #666;
            @include transition();
        }
    }
}
.choose-buy-lists {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    li {
        width: 50%;
    }
    .item {
        display: flex;
        padding: 20px 45px 20px 20px;
        margin: 0 10px 20px;
        background-color: #fff;
        &:hover {
            .img-box {
                .img {
                    @include touming(85);
                }
            }
            .tt {
                color: $hoverColor;
            }
        }
    }
    .img-box {
        width: 268px;
        .img {
            display: block;
            position: relative;
            height: 0;
            padding-top: (244/268) * 100%;
            backface-visibility: hidden;
            overflow: hidden;
            @include transition(.4s);
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .info-box {
        flex: 1;
        width: 0;
        padding-left: 38px;
        overflow: hidden;
    }
    .tt {
        width: 100%;
        margin-top: 30px;
        line-height: 1;
        font-size: 18px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include transition(.4s);
    }
    .p {
        height: 100px;
        margin-top: 15px;
        line-height: 25px;
        font-size: 13px;
        color: #7d7d7d;
        overflow: hidden;
    }
    .watch {
        margin-top: 25px;
        font-size: 0;
        color: #666;
        .iconfont {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
        }
        .num {
            display: inline-block;
            vertical-align: middle;
            padding-left: 3px;
            font-size: 12px;
        }
    }
}
.result-wrap {
    padding: 80px 0;
    background: #f4f4f4;
    @media screen and (max-width: 750px) {
        padding: 0 15px;
    }
    .uweb-search {
        //margin-top: 84px;
        //margin-bottom: 100px;
        //搜索框
        &-input-con {
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding-right: 25px;
        }
        &-select-con {
            float: left;
            width: 37%;
            box-sizing: border-box;
            padding-right: 25px;
        }
        @media screen and (max-width: 750px) {
            &-input-con,
            &-select-con {
                float: none;
                width: 100%;
                padding-right: 0;
                margin-bottom: 10px;
            }
        }
        &-top {
            position: relative;
            margin-bottom: 40px;
            height: 44px;
            width: 100%;
            &-s {
                margin-bottom: 40px;
                .uweb-search-input-con {
                    float: left;
                    width: 87%;
                }
            }
            @media screen and (max-width: 750px) {
                height: auto;
                &-s {
                    .uweb-search-input-con {
                        width: 100%;
                    }
                }
            }
            &-text {
                float: left;
                width: 80px;
                font-size: 14px;
                color: #666;
                line-height: 44px;
            }
            &-input {
                margin: 0 0 0 80px;
                height: 44px;
                border: 1px solid #e9e9e9;
                input {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    font-size: 14px;
                    padding: 0 20px;
                }
            }
            &-input2 {
                box-sizing: border-box;
                margin-left: 80px;
                height: 44px;
                border: 1px solid #e9e9e9;
                input {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border: 0;
                    font-size: 14px;
                    padding: 0 20px;
                }
            }
            &-button {
                float: left;
                width: 13%;
                height: 46px;
                background: $mainColor;
                cursor: pointer;
                color: #fff;
                font-size: 14px;
                line-height: 46px;
                text-align: center;
                appearance: none;
                border: none;
                padding: 0;
                &:hover {
                    background: $hoverColor;
                }
                @media screen and (max-width: 750px) {
                    float: none;
                    width: 100%;
                }
            }
        }
        //搜索内容
        &-content {
            margin: 0 -12px 40px;
            font-size: 0;
            li {
                display: inline-block;
                vertical-align: top;
                padding: 0 12px;
                box-sizing: border-box;
                width: 50%;
                height: 156px;
                margin-bottom: 30px;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #e9e9e9;
                    box-sizing: border-box;
                    padding: 25px 40px 30px;
                    transition: all .3s ease;
                    &:hover {
                        background: #fff;
                        .text {
                            color: $hoverColor;
                        }
                        .btn {
                            background: $hoverColor;
                        }
                    }
                    .text {
                        font-size: 16px;
                        font-weight: bold;
                        color: #666;
                        transition: all .3s ease;
                        line-height: 22px;
                        max-height: 44px;
                        overflow: hidden;
                    }
                    .time {
                        margin-top: 5px;
                        font-size: 14px;
                        font-family: "Arial";
                        color: #999;
                    }
                    .btn {
                        margin-top: 20px;
                        width: 95px;
                        height: 28px;
                        background: #999;
                        font-size: 14px;
                        color: #fff;
                        line-height: 28px;
                        text-align: center;
                        transition: all .3s ease;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                margin-right: 0;
                margin-left: 0;
                li {
                    &:nth-child(2n+1) {
                        padding: 0 12px 0 0;
                    }
                    &:nth-child(2n) {
                        padding: 0 0 0 12px;
                    }
                }
            }
            @media screen and (max-width: 480px) {
                li {
                    float: none;
                    width: 100%;
                    height: auto;
                    &:nth-child(2n+1),
                    &:nth-child(2n) {
                        padding: 0;
                    }
                    a {
                        .text {
                            max-height: none;
                        }
                    }
                }
            }
        }
    }
}