.insp-con {
    background-image: url(../images/insp_bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 20px;
}
.insp-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    .insp-title {
        > div {
            display: inline-block;
            vertical-align: top;
        }
        i {
            display: inline-block;
            vertical-align: top;
            font-size: 46px;
            color: $mainColor;
            margin-right: 5px;
        }
        .cn {
            font-size: 24px;
            color: #fff;
        }
        .en {
            font-family: Arial;
            font-size: 12px;
            color: #fff;
        }
    }
    .insp-more {
        width: 130px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        transition: all .2s;
        background-color: $mainColor;
        &:hover {
            background-color: $hoverColor;
        }
    }
}
.insp-box {
    background-color: #fff;
    border: 3px solid $mainColor;
    border-radius: 3px;
    .item {
        overflow: hidden;
        &:hover {
            .img span img {
                transform: scale(1.1);
            }
            .t {
                color: $hoverColor;
            }
        }
    }
    .img {
        float: left;
        margin-right: 35px;
        span {
            display: block;
            width: 100%;
            padding-bottom: (237/260) * 100%;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transition: all .4s;
            }
        }
    }
    .info {
        overflow: hidden;
    }
    .t {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
        transition: all .2s;
        margin-bottom: 12px;
    }
    .p {
        font-size: 14px;
        line-height: 26px;
        color: #7d7d7d;
        overflow: hidden;
    }
    .views {
        font-size: 12px;
        color: #666;
        line-height: 20px;
        &:before {
            font-family: 'iconfont';
            content: '\e64c';
            font-size: 16px;
            display: inline-block;
            vertical-align: top;
            line-height: 20px;
            margin-right: 3px;
        }
    }
}
.insp-full {
    margin-bottom: 70px;
    .insp-box {
        font-size: 0;
    }
    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        padding: 35px;
    }
    .img {
        width: 260px;
    }
    .info {
        padding-top: 10px;
    }
    .t {
        font-size: 24px;
        line-height: 30px;
    }
    .p {
        height: 26px * 4;
        margin-bottom: 20px;
    }
}
.insp-half {
    font-size: 0;
    .half-box {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        margin-bottom: 10px;
        &:nth-child(2n+1) {
            padding-right: 5px;
        }
        &:nth-child(2n) {
            padding-left: 5px;
        }
    }
    .insp-box {
        padding: 15px;
        .item {
            display: block;
            padding: 10px;
        }
        .img {
            width: 220px;
        }
        .info {
            padding-top: 30px;
        }
        .t {
            font-size: 18px;
            line-height: 24px;
        }
        .p {
            height: 26px * 3;
            margin-bottom: 10px;
        }
    }
}