.wp1626 {
    width: 1626px;
    margin: 0 auto;
    max-width: 100%;
}

.wp1538 {
    width: 1538px;
    margin: 0 auto;
    max-width: 100%;
    @media screen and (max-width: 1500px) {
        width: 1400px;
        margin: 0 auto;
    }
}

.wp1440 {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
}

.wp1378 {
    width: 1378px;
    max-width: 100%;
    margin: 0 auto;
}

.wp1348 {
    width: 1348px;
    margin: 0 auto;
    max-width: 100%;
}

.wp1250 {
    width: 1250px;
    margin: 0 auto;
}

.wp1200 {
    width: 1200px;
    margin: 0 auto;
}