.header {
    background-color: #fff;

    .logo {
        padding-top: 46px;
        text-align: center;

        a {
            display: inline-block;
            width: 247px;
        }

        img {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-lists-box {
            display: flex;
        }

        .lists {
            position: relative;
            padding: 15px 0;
            cursor: pointer;

            .hover-box {
                object-fit: 0;
                visibility: hidden;
                position: absolute;
                left: -28%;
                text-align: center;
                z-index: 33;
                transition: transform .5s;
                transform: translateY(15PX);

                li {
                    width: 180px;
                    background-color: rgba(0, 0, 0, .5);
                    padding: 0 11%;
                    box-sizing: border-box;
                }

                a {
                    display: block;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #ddd;
                    line-height: 66px;
                    white-space: nowrap;
                    border-bottom: 1px solid rgba(255, 255, 255, .2);

                    &:hover {
                        color: #00627d;
                    }
                }

                .iconfont {
                    color: #022e3a;
                    transform: rotate(180deg);
                    margin-top: -10px;
                    margin-bottom: 13px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            &:hover {
                .item {
                    font-weight: bold;
                    color: $hoverColor;
                }

                .hover-box {
                    transition: all .5s;
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }

                .sub {
                    transform: translate(-50%, 0);
                    visibility: visible;
                    @include touming(100);
                }
            }

            &.on {
                .item {
                    font-weight: bold;
                    color: $hoverColor;
                }
            }
        }

        .item {
            display: block;
            padding: 15px 30px;
            font-size: 14px;
            color: #666;
            @include transition();
        }

        .sub {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 20px);
            width: 180px;
            z-index: 99;
            visibility: hidden;
            @include touming(0);
            @include transition();
        }

        .sub-item {
            display: block;
            padding: 20px 0;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background-color: $mainColor;
            border-bottom: 1px solid rgba(255, 255, 255, .2);
            @include transition();

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: $hoverColor;
            }
        }

        .lang {
            margin-left: 10px;
            color: #666;
            line-height: 45px;
            font-size: 14px;

            i,
            span {
                display: inline-block;
                vertical-align: top;
                line-height: 45px;
            }

            i {
                font-size: 18px;
            }

            &:hover {
                color: $mainColor;
            }
        }

        .search {
            position: relative;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
                .search-from {
                    visibility: visible;
                    @include touming(100);
                }
            }
        }

        .hover-search {
            display: inline-block;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            font-size: 18px;
            color: #666;
        }

        .search-from {
            position: absolute;
            top: 50%;
            left: 0;
            width: 245px;
            height: 45px;
            font-size: 0;
            transform: translateY(-50%);
            border: 1px solid #ddd;
            z-index: 9;
            visibility: hidden;
            @include touming(0);
            @include transition();
        }

        .btn {
            display: inline-block;
            vertical-align: top;
            width: 45px;
            height: 100%;
            background-color: #333;
            border: none;
            cursor: pointer;

            .iconfont {
                font-size: 18px;
                color: #fff;
            }
        }

        .input-box {
            display: inline-block;
            vertical-align: top;
            width: 200px;
            height: 100%;
            padding: 0 10px;
            box-sizing: border-box;
        }

        .input {
            width: 100%;
            height: 100%;
            font-size: 14px;
            color: #666;
            background: none;
            border: none;
        }
    }
}

// 底部
.footer {
    padding: 80px 20px 0px;
    background-color: #0c0c0c;

    .footer-con {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 7%;
    }

    .left {
        width: 400px;

        .left-title {
            font-size: 18px;
            color: #fff;
            display: flex;
            margin-bottom: 40px;
            margin-left: 8%;

            .line {
                width: 6px;
                height: 16px;
                background-color: #1c485c;
                margin-right: 10px;
                margin-top: 1.5%;
            }
        }

        .left-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            box-sizing: content-box;

            .left-item {
                width: calc(100% / 3);
                margin-bottom: 29px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;

                .item-top {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    bottom: 110%;


                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .item-bot {
                    cursor: pointer;

                    .item-img {
                        margin-bottom: 9px;
                    }

                    .item-title {
                        font-size: 12px;
                        color: #9a9a9a;
                        white-space: nowrap;

                        a {
                            font-size: 12px;
                            color: #9a9a9a;
                        }
                    }
                }

                // &:nth-child(n+3){
                //     .item-top{
                //         transform: rotate(180deg);
                //     }
                // }
                &:hover {

                    .item-top {
                        transition: all .5s;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

    }

    .right {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        overflow: hidden;

        dl:last-child {
            dt {
                padding-right: 0;

                &::after {
                    display: none;
                }
            }

            dd {
                padding-right: 0;
            }
        }

        dt {
            position: relative;
            padding: 0 30px;
            margin-bottom: 24px;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 8px;
                background-color: #424242;
                transform: translate(0, -50%);
            }

            a {
                line-height: 14px;
                font-size: 14px;
                color: #fff;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        dd {
            margin-bottom: 10px;
            padding: 0 30px;

            a {
                line-height: 14px;
                font-size: 14px;
                color: #9a9a9a;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media screen and (max-width: 1500px) {

            dt,
            dd {
                padding: 0 20px;
            }
        }

        @media screen and (max-width: 1400px) {

            dt,
            dd {
                padding: 0 15px;
            }
        }
    }

    .bottom-box {
        width: 100%;
        height: 100px;
        line-height: 100px;
        box-sizing: border-box;
        text-align: center;
        font-size: 13px;
        color: #d6d6d6;
        margin-top: 64px;
        border-top: 1px solid rgba(255, 255, 255, .2);

        a {
            font-size: 13px;
            color: #d6d6d6;
        }
    }
}

.inside-banner-wrap {
    position: relative;
    height: 0;
    padding-top: (497/1920) * 100%;
    overflow: hidden;

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.inside-menu-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    box-shadow: 0 5px 18px 5px rgba(0, 0, 0, .1);

    li {
        padding: 0 5px;
    }

    .item {
        display: block;
        height: 35px;
        padding: 0 30px;
        line-height: 35px;
        font-size: 14px;
        color: #777;
        @include transition();

        &:hover,
        &.on {
            background-color: $hoverColor;
            color: #fff;
        }
    }
}

.inside-menu-sd-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background: #fff;
    border-top: 1px solid #eee;
    box-shadow: 0 8px 10px 5px rgba(0, 0, 0, .1);
    box-sizing: border-box;

    .item {
        display: block;
        height: 35px;
        padding: 0 30px;
        line-height: 35px;
        font-size: 14px;
        color: #777;
        @include transition();

        &:hover,
        &.on {
            background-color: $hoverColor;
            color: #fff;
        }
    }
}

.page {
    padding: 90px 20px;
    background-color: #f4f4f4;
}

.form {
    display: block;
    margin: 0 auto;

    .row {
        display: flex;

        .cell {
            &:nth-child(2) {
                margin-left: 8px;
            }
        }
    }

    .cell {
        margin-bottom: 8px;
        flex: 1;
        display: flex;
        align-items: center;
        background-color: #fff;
    }

    .word {
        padding-left: 17px;
        font-size: 14px;
        color: #666;
        line-height: 46px;
    }

    .input {
        flex: 1;
        overflow: hidden;

        input {
            display: block;
            width: 100%;
            height: 46px;
            line-height: 46px;
            font-size: 14px;
            color: #555;
            border: none;
            appearance: none;
            background-repeat: no-repeat;
        }
    }

    .select {
        position: relative;
        flex: 1;
        height: 50px;
        overflow: hidden;

        select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 50px;
            font-size: 14px;
            color: #555;
            border: none;
            background: none;
            appearance: none;
            outline: none;
            cursor: pointer;
            z-index: 2;
        }

        .iconfont {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(0, -50%);
            font-size: 12px;
            color: $mainColor;
            z-index: 1;
        }
    }

    .cer {
        margin-left: 10px;
        margin-right: 10px;
    }

    .text {
        padding: 0 17px;

        textarea {
            display: block;
            width: 100%;
            border: none;
            background: none;
            appearance: none;
            font-size: 14px;
            line-height: 28px;
            color: #555;
            overflow: auto;
        }
    }

    .row-text {
        background-color: #fff;
        margin-bottom: 8px;
    }

    .btn {
        display: block;
        width: 226px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        background: $mainColor;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        border: none;
        appearance: none;
        transition: all .2s ease;

        &:hover {
            background: $hoverColor;
        }
    }

    &.join {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .cell {
            margin-bottom: 5px;
            background-color: #f5f5f5;
        }

        .word {
            line-height: 50px;
        }

        .input input {
            height: 50px;
            line-height: 50px;
            background: none;
        }

        .btn {
            width: 100%;
            height: 54px;
            line-height: 54px;
        }
    }

    &.loving {
        .cell {
            background: none;
            border: 1px solid rgba(255, 255, 255, .2);
        }

        .input input {
            color: #fff;
            background: none;
        }

        .word {
            color: #fff;
        }

        .btn {
            width: 100%;
            height: 57px;
            line-height: 57px;
            background: #022937;

            &:hover {
                background: $hoverColor;
            }
        }
    }
}

.bread {
    font-size: 14px;
    color: #fff;
    line-height: 24px;

    a {
        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }

    &:before {
        font-family: 'iconfont';
        content: '\e615';
        color: $mainColor;
        display: inline-block;
        vertical-align: top;
        line-height: 24px;
        font-size: 24px;
        margin-right: 10px;
    }
}

.sidebar-wrap {
    position: fixed;
    right: 0;
    top: 60em;
    width: 70px;
    z-index: 9999;

    @media screen and (max-width: 1500px) {
        top: 40em;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        background-color: #ececec;
        @include transition();

        .icon {
            color: #0c0c0c;
            @include transition();
        }

        .iconfont {
            display: inline-block;
            margin-top: -5px;
            font-size: 20px;
        }

        .p {
            margin-top: 5px;
            line-height: 1;
            text-align: center;
            font-size: 14px;
            color: #666;
            @include transition();
        }

        &:hover {
            background-color: $hoverColor;

            .icon,
            .p {
                color: #fff;
            }
        }
    }
}