.case-list-con {
    padding: 110px 0 80px;
    background-color: #f4f4f4;
}
.case-list {
    width: 1378px;
    margin: 0 auto;
    max-width: 100%;
    font-size: 0;
    li {
        width: 25%;
        box-sizing: border-box;
        padding: 0 15px;
        margin-bottom: 40px;
        display: inline-block;
        vertical-align: top;
    }
    .wrapper {
        background-color: #fff;
        cursor: pointer;
        transition: all .5s ease;
        backface-visibility: hidden;
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            .img img {
                transform: scale(1.1);
            }
        }
    }
    .img {
        width: 100%;
        position: relative;
        padding-bottom: (184/312) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            transition: all .8s ease;
        }
    }
    .info {
        box-sizing: border-box;
        height: 168px;
        padding: 28px 40px;
    }
    .t {
        font-size: 16px;
        color: #000;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
    }
    .p {
        font-size: 13px;
        line-height: 22px;
        color: #777;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 15px;
    }
    .arrow {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid $mainColor;
        text-align: center;
        line-height: 24px;
        font-size: 12px;
        color: $mainColor;
    }
    .hidden {
        display: none;
    }
}
.honor-hd {
    display: flex;
    width: 1348px;
    max-width: 100%;
    margin: 0 auto 35px;
    .hidden {
        display: none;
    }
}
.honor-left {
    width: 826px;
    .swiper-slide {
        height: 0;
        padding-top: (468/826) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .swiper-pagination {
        left: 0;
        bottom: 20px;
        width: 100%;
        padding: 0 36px;
        text-align: right;
        box-sizing: border-box;
    }
    .my-bullet {
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background: #f5f5f5;
        border-radius: 50%;
        transition: all .2s ease;
    }
    .my-bullet-active {
        background: $mainColor;
    }
}
.honor-right {
    flex: 1;
    padding: 100px 80px 80px;
    background: #fff;
    overflow: hidden;
    .title {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 20px;
        line-height: 1;
        font-size: 30px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 25px;
            height: 3px;
            background: $mainColor;
        }
    }
    .msg {
        height: 90px;
        line-height: 30px;
        font-size: 15px;
        color: #777;
        overflow: hidden;
    }
    .read {
        padding-top: 40px;
        font-size: 12px;
        color: #777;
        i {
            display: inline-block;
            vertical-align: middle;
            color: #777;
        }
    }
    .more-btn {
        display: block;
        width: 170px;
        height: 40px;
        margin-top: 25px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background: $mainColor;
        @include transition();
        &:hover {
            background: $hoverColor;
        }
    }
}
.honor-item {
    display: block;
    padding: 8px;
    background: #fff;
    transition: all .4s ease;
    cursor: pointer;
    .img-box {
        position: relative;
        height: 0;
        padding-top: (200/312) * 100%;
        backface-visibility: hidden;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include transition(.4s);
        }
    }
    .info-box {
        padding: 28px 10px 20px;
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        .tt {
            flex: 1;
            line-height: 1;
            font-size: 16px;
            color: #666;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include transition(.4s);
        }
        .read {
            font-size: 12px;
            color: #777;
            i {
                display: inline-block;
                vertical-align: middle;
                color: #777;
            }
        }
        .p {
            font-size: 14px;
            color: #999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
        .img-box img {
            transform: scale(1.05);
        }
        .tt {
            color: $mainColor;
        }
    }
}
.case-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9909;
    display: none;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);
    }
    &-box {
        position: absolute;
        width: 1054px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backface-visibility: hidden;
        min-height: 412px;
        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 46px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            color: #fff;
            background-color: $mainColor;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
        }
        .case-dialog-swiper {
            width: 494px;
            float: left;
            .swiper-slide {
                img {
                    display: block;
                    width: 100%;
                }
            }
            .swiper-pagination {
                position: static;
                text-align: right;
                margin-top: 5px;
                &-bullet {
                    opacity: 1;
                    background-color: $mainColor;
                    margin: 0 0 0 7px;
                    outline: none;
                    &-active {
                        background-color: $hoverColor;
                    }
                }
                &.hideStyle {
                    display: none;
                }
            }
        }
        .case-dialog-info {
            width: 330px;
            padding-top: 20px;
            float: right;
            margin-right: 40px;
            .title {
                font-size: 24px;
                padding-bottom: 10px;
                border-bottom: 1px dashed #ccc;
            }
            .p {
                font-size: 14px;
                line-height: 30px;
                color: #666;
                padding-top: 18px;
            }
        }
    }
}