.product-detail-con {
    padding: 75px 20px 100px;
    background-color: #f4f4f4;
    background-image: url(../images/product_bg.jpg);
    background-position: center top;
    background-repeat: no-repeat;
}
.product-bread {
    display: flex;
    justify-content: space-between;
    .bread {
        margin-left: 40px;
    }
    .back-btn {
        display: block;
        width: 132px;
        height: 36px;
        line-height: 36px;
        box-sizing: border-box;
        padding: 0 20px;
        position: relative;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: all .2s ease;
        background-color: $mainColor;
        border-radius: 18px;
        &:after {
            position: absolute;
            display: block;
            right: 18px;
            top: 0;
            line-height: 36px;
            color: #fff;
            font-size: 14px;
            font-family: 'iconfont';
            content: '\e8a9';
        }
        &:hover {
            background-color: $hoverColor;
        }
    }
}
.product-detail-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: 70px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, .1);
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
.product-detail-swiper {
    width: 662px;
    .swiper-container {
        width: 100%;
    }
    .swiper-slide {
        height: 435px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .button-prev,
    .button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        width: 50px;
        height: 100%;
        margin-top: 0;
        outline: none;
        cursor: pointer;
        z-index: 10;
        .iconfont {
            display: block;
            font-size: 36px;
            color: $mainColor;
        }
    }
    .button-prev {
        left: 0;
    }
    .button-next {
        right: 0;
    }
}
.product-detail-intro {
    width: 546px;
    margin-right: 70px;
    @media screen and (max-width: 1500px) {
        margin-right: 0;
    }
    .title {
        font-size: 30px;
        color: #000;
        padding: 15px 0 25px;
    }
}
.product-detail-article {
    line-height: 28px;
    color: #777;
    @include articleReset(14px, 28px);
    .prop-box {
        border-top: 1px dashed #bcbcbc;
        border-bottom: 1px dashed #bcbcbc;
        padding-top: 25px;
        margin-bottom: 25px;
        .item {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 20px;
            width: 49%;
        }
    }
    .b {
        font-size: 20px;
        color: #444;
        margin-bottom: 20px;
    }
}
.product-detail-msg {
    @include articleReset();
    margin-top: 30px;
    padding: 50px 200px;
    line-height: 30px;
    font-size: 14px;
    color: #777;
    background: #fff;
    h4 {
        margin-bottom: 25px;
        text-align: center;
        font-size: 24px;
        color: #333;
    }
}
.product-center-con {
    width: 100%;
    background-image: url(../images/product_center.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    height: 760px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
        width: 1228px;
        display: flex;
        align-items: center;
    }
    .hd {
        width: 118px;
        margin-right: 10px;
        .item {
            margin-bottom: 8px;
            width: 118px;
            height: 118px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: $mainColor;
            cursor: pointer;
            &:hover,
            &.on {
                background-color: $hoverColor;
            }
            .icon {
                width: 50px;
                img {
                    display: block;
                    width: 50px;
                    height: 50px;
                }
            }
            .p {
                color: #fff;
                font-size: 15px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .bd {
        position: relative;
        flex: 1;
        overflow: hidden;
        padding: 60px 0;
        .con:first-child {
            display: block;
        }
        &:after {
            position: absolute;
            left: 180px;
            right: 0;
            top: 0;
            bottom: 0;
            box-sizing: border-box;
            border: 4px solid #c59c81;
            background-color: #fff;
            border-radius: 5px;
            z-index: 1;
            display: none;
            content: '';
        }
    }
    .con {
        display: none;
        position: relative;
        z-index: 2;
        .title {
            position: absolute;
            top: 30px;
            font-size: 30px;
            color: #fff;
            z-index: 6;
            left: 50%;
            span {
                font-family: Didot;
                color: #444;
                text-transform: uppercase;
            }
        }
        .bg {
            position: absolute;
            left: 180px;
            right: 0;
            top: 0;
            bottom: 0;
            box-sizing: border-box;
            border: 4px solid #c59c81;
            background-color: #fff;
            border-radius: 5px;
            display: none;
            z-index: 1;
        }
        .product-center-prev,
        .product-center-next {
            position: absolute;
            right: 70px;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border: 2px solid $mainColor;
            cursor: pointer;
            outline: none;
            border-radius: 100%;
            font-size: 12px;
            color: $mainColor;
            top: 50%;
            z-index: 6;
            transition: all .2s;
            &:hover {
                border-color: lighten(#003a4a, 5%);
                color: lighten(#003a4a, 5%);
            }
        }
        .product-center-prev {
            margin-top: -40px;
        }
        .product-center-next {
            margin-top: 10px;
        }
    }
}
.product-center-swiper {
    z-index: 5;
    margin-right: 150px;
    padding: 20px 0;
    height: 336px;
    position: relative;
    .swiper-wrapper {
        align-items: center;
    }
    .swiper-slide {
        position: relative;
        transition: all .3s;
        transform-origin: center center;
        height: auto;
        .img {
            position: relative;
            z-index: 2;
            overflow: hidden;
            transition: all .3s;
            height: 137px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
            }
        }
        .t {
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #666;
        }
        &-active {
            width: 510px !important;
            box-shadow: 0 0 30px rgba(0, 0, 0, .2);
            .img {
                background-color: #fff;
                padding: 3px;
                border-radius: 3px;
                box-sizing: border-box;
                height: 100%;
                &:after {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    bottom: 3px;
                    left: 3px;
                    background-image: url(../images/product_shadow.png);
                    background-position: center center;
                    background-repeat: repeat-x;
                    background-size: 1px 100%;
                    z-index: 2;
                    content: '';
                }
            }
            .t {
                position: absolute;
                left: 0;
                bottom: 15px;
                color: #fff;
                font-size: 24px;
                z-index: 4;
                width: 100%;
            }
        }
    }
}