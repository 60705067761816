.brand-wrap {
    line-height: 30px;
    font-size: 14px;
    color: #666;
    @include articleReset();
    .img-box {
        float: left;
        margin-right: 60px;
        margin-bottom: 30px;
    }
    .info-box {
        .title {
            display: flex;
            margin-bottom: 35px;
        }
        .cn {
            line-height: 1;
            font-size: 28px;
            color: #000;
        }
        .en {
            padding-left: 5px;
            line-height: 36px;
            font-size: 36px;
            font-family: "Didot";
            color: #333;
            font-weight: bold;
            text-transform: uppercase;
        }
        p {
            margin-bottom: 5px;
        }
    }
    .loving-memory-con {
        margin-top: 50px;
        padding: 80px 20px 0;
        background-image: url(../images/loving_5.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        .t {
            text-align: center;
            font-size: 30px;
            color: #fff;
            margin-bottom: 24px;
        }
    }
    .loving-memory-box {
        padding: 50px 0 70px;
        background-color: #fff;
        box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
        .icon {
            text-align: center;
        }
    }
    .lovings-memory-swiper {
        width: 1100px;
        margin: 0 auto;
        //height: 450px;
        padding-top: 20px;
        &:before {
            position: absolute;
            left: 50%;
            width: 2px;
            height: 100%;
            background-color: $mainColor;
            content: '';
            display: block;
            margin-left: -1px;
            top: 0;
        }
        .swiper-wrapper {
            z-index: 5;
        }
        .swiper-slide {
            overflow: hidden;
            &:nth-child(2n+1) {
                .info {
                    float: left;
                    text-align: right;
                    padding-right: 30px;
                    &:before {
                        right: -7px;
                    }
                }
            }
            &:nth-child(2n) {
                .info {
                    float: right;
                    padding-left: 30px;
                    &:before {
                        left: -7px;
                    }
                }
            }
            .info {
                width: 50%;
                box-sizing: border-box;
                position: relative;
                &:before {
                    position: absolute;
                    top: 5px;
                    width: 14px;
                    height: 14px;
                    background-image: url(../images/loving_7.jpg);
                    content: '';
                    display: block;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
            .time {
                font-size: 18px;
                color: $mainColor;
                margin-bottom: 5px;
            }
            .p {
                font-size: 14px;
                line-height: 24px;
                height: 48px;
                color: #777;
            }
        }
    }
    .loving-memory-btn {
        font-size: 0;
        text-align: center;
        > div {
            display: inline-block;
            vertical-align: top;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            cursor: pointer;
            background-color: $mainColor;
            border-radius: 100%;
            margin: 0 2px;
            outline: none;
        }
    }
}
.ex-brand-title {
    margin-bottom: 25px;
    .cn {
        margin-bottom: 10px;
        line-height: 1;
        font-size: 28px;
        color: #333;
    }
    .en {
        line-height: 1;
        font-size: 30px;
        font-family: "Arial";
        color: #999;
        text-transform: uppercase;
    }
    &.tac {
        text-align: center;
    }
    &.color-fff {
        .cn,
        .en {
            color: #fff;
        }
    }
}
.ex-brand-01 {
    padding: 120px 20px 90px;
    background-color: #f4f4f4;
    .content {
        display: flex;
        align-items: center;
        @include articleReset();
        line-height: 24px;
        font-size: 14px;
        color: #666;
        p {
            margin-bottom: 20px;
        }
    }
    .img-box {
        width: 654px;
        height: 380px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .info-box {
        flex: 1;
        padding-left: 60px;
        overflow: hidden;
    }
}
.ex-brand-02 {
    padding: 50px 20px;
    background-color: #fff;
    .content {
        position: relative;
        padding: 30px;
    }
    .list-box {
        display: flex;
        margin-left: -120px;
    }
    .item {
        width: calc-percent(1, 4);
    }
    .box {
        margin-left: 120px;
        cursor: pointer;
        &:hover {
            .hover-img {
                visibility: visible;
                @include touming(100);
            }
        }
    }
    .con {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .img {
            position: relative;
            width: 234px;
            height: 234px;
            border-radius: 50%;
            overflow: hidden;
            z-index: 1;
            img {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .tt {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            .icon {
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
            }
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
            }
            h5 {
                margin-top: 12px;
                line-height: 1;
                text-align: center;
                font-size: 20px;
                color: #fff;
            }
        }
    }
    .hover-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        visibility: hidden;
        @include touming(0);
        @include transition(.1s);
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
.ex-brand-03 {
    padding: 75px 20px 110px;
    background-color: #f4f4f4;
    .content {
        @include articleReset();
        display: flex;
        line-height: 24px;
        font-size: 14px;
        color: #666;
        .img {
            width: 566px;
            height: 398px;
            padding-left: 68px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .video {
            width: 566px;
            height: 398px;
            margin-left: 68px;
            background-color: #000;
            video {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.ex-brand-04 {
    padding: 100px 20px;
    background-color: #f4f4f4;
    background-image: url("../images/ex_article_5.jpg");
    background-position: top left;
    background-repeat: no-repeat;
    .bfc {
        overflow: hidden;
    }
    .content {
        position: relative;
        margin-top: 25px;
        padding: 70px 60px;
        background: #fff;
        .swiper-slide {
            padding: 200px 0;
            &:nth-child(even) {
                .detail {
                    justify-content: flex-end;
                    bottom: auto;
                    top: 0;
                }
            }
        }
        .img {
            position: relative;
            height: 0;
            padding-top: (150/224) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .detail {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 200px;
            padding: 24px 30px;
            border-left: 1px solid #ccc;
            box-sizing: border-box;
        }
        .date {
            margin-bottom: 5px;
            line-height: 1;
            font-weight: 200;
            font-size: 48px;
            color: $mainColor
        }
        .p {
            line-height: 24px;
            font-size: 14px;
            color: #666;
        }
        .button-prev,
        .button-next {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 40px;
            height: 40px;
            line-height: 34px;
            text-align: center;
            border: 2px solid $mainColor;
            border-radius: 50%;
            box-sizing: border-box;
            outline: none;
            cursor: pointer;
            .iconfont {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                font-size: 18px;
                color: $mainColor;
            }
        }
        .button-prev {
            left: 10px;
        }
        .button-next {
            right: 10px;
        }
    }
}
.news-detail-wrap {
    padding: 75px 200px;
    background-color: #fff;
    @include articleReset();
    .title-box {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px dashed rgba(178, 178, 178, .6);
        .title {
            margin-bottom: 20px;
            line-height: 30px;
            text-align: center;
            font-size: 24px;
            color: #333;
        }
        .data {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #666;
            span {
                display: inline-block;
                padding: 0 10px;
            }
        }
    }
    .content-box {
        line-height: 25px;
        font-size: 12px;
        color: #666;
    }
}
.news-nav-box {
    display: flex;
    width: 100%;
    height: 65px;
    margin-top: 15px;
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 100%;
        background-color: $mainColor;
        @include transition();
        & + .box {
            border-left: 1px solid rgba(255, 255, 255, .5);
        }
        &:hover {
            background-color: $hoverColor;
        }
    }
    .img {
        width: 28px;
        height: 20px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .p {
        padding: 0 15px;
        line-height: 1;
        font-size: 16px;
        color: #f4f4f4;
    }
}
.video-con {
    margin: 0 auto;
    width: 1154px;
    .right {
        float: right;
        width: 288px;
        background-color: #fff;
        overflow: hidden;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 24px 34px;
        height: 698px;
    }
    .left {
        float: left;
        width: 852px;
    }
}
.video-swiper {
    width: 100%;
    height: 584px;
    .swiper-slide {
        cursor: pointer;
        .img {
            width: 100%;
            height: 144px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .p {
            text-align: center;
            font-size: 16px;
            line-height: 34px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover,
        &.on {
            .p {
                color: $hoverColor;
            }
        }
    }
}
.video-swiper-btn {
    font-size: 0;
    text-align: center;
    > div {
        display: inline-block;
        vertical-align: top;
        width: 54px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        color: #fff;
        outline: none;
        transition: background-color .2s ease;
        cursor: pointer;
    }
    .video-swiper-prev {
        background-color: $hoverColor;
        &:hover {
            background-color: lighten($hoverColor, 5%);
        }
    }
    .video-swiper-next {
        background-color: #32606d;
        &:hover {
            background-color: lighten(#32606d, 5%);
        }
    }
}
.video-box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    padding: 30px 38px;
    min-height: 698px;
    .video-bd {
        display: none;
        &:first-child {
            display: block;
        }
    }
    .video-play {
        width: 100%;
        height: 500px;
        position: relative;
        overflow: hidden;
        .mejs__container,
        .player,
        .mejs__overlay,
        .mejs__poster,
        .mejs__overlay-error > img {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .video-article {
        font-size: 14px;
        line-height: 30px;
        color: #666;
        padding-top: 20px;
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}
.ex-loving-title {
    margin-bottom: 20px;
    .cn {
        margin-bottom: 10px;
        line-height: 1;
        font-size: 28px;
        color: #333;
    }
    .en {
        line-height: 1;
        font-size: 30px;
        font-family: "Arial";
        color: #999;
        text-transform: uppercase;
    }
    &.tac {
        text-align: center;
    }
    &.color-fff {
        .cn,
        .en {
            color: #fff;
        }
    }
}
.ex-loving-article-con {
    padding: 85px 20px 100px;
    background-color: #fff;
    .content {
        display: flex;
        align-items: center;
        line-height: 30px;
        font-size: 14px;
        color: #666;
    }
    .left {
        flex: 1;
        padding-right: 96px;
        overflow: hidden;
        p {
            margin-bottom: 10px;
        }
    }
    .right {
        width: 675px;
        height: 398px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
.ex-loving-history-con {
    padding: 50px 20px;
    background-color: #f5f5f5;
    .content {
        position: relative;
        padding-bottom: 40px;
    }
    .list-box {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        li {
            width: calc-percent(1, 4);
        }
        .box {
            display: block;
            position: relative;
            margin: 0 12px 20px;
            overflow: hidden;
            &:hover {
                .bg img {
                    transform: scale(1.05);
                }
                .tt {
                    visibility: hidden;
                    @include touming(0);
                }
                .expend {
                    transform: translate(0, 0);
                    visibility: visible;
                    @include touming(100);
                }
            }
        }
        .bg {
            position: relative;
            height: 0;
            padding-top: (167/320) * 100%;
            backface-visibility: hidden;
            overflow: hidden;
            z-index: 1;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include transition(.4s);
            }
        }
        .tt {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 58px;
            padding: 0 20px;
            background-image: url("../images/ex_bg.png");
            background-size: cover;
            background-position: left bottom;
            background-repeat: no-repeat;
            z-index: 2;
            visibility: visible;
            @include touming(100);
            @include transition(.4s);
            .icon {
                width: 20px;
                height: 22px;
            }
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
            .txt {
                padding-left: 5px;
                font-size: 20px;
                color: #fff;
            }
        }
        .expend {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            background-color: rgba(0, 58, 74, .8);
            box-sizing: border-box;
            z-index: 3;
            transform: translate(0, 100%);
            visibility: hidden;
            @include touming(0);
            @include transition(.4s);
            .case {
                margin-bottom: 2px;
                line-height: 1;
                font-size: 20px;
                color: #fff;
            }
            .address {
                margin-bottom: 15px;
                line-height: 1;
                font-size: 30px;
                color: #fff;
            }
            .year {
                position: relative;
                padding: 0 12px;
                font-size: 14px;
                color: #fff;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 8px;
                    height: 1px;
                    background-color: #fff;
                    transform: translate(0, -50%);
                }
                &::before {
                    left: 0;
                }
                &::after {
                    right: 0;
                }
            }
        }
    }
    .arrow-bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        height: 40px;
        transform: translate(-50%, 0);
        transition: all .2s ease;
        cursor: pointer;
        i {
            display: block;
            position: absolute;
            left: 0;
            line-height: 1;
            font-size: 60px;
            color: #c9c9c9;
            &:nth-child(1) {
                top: 0;
                animation: animA .6s ease infinite;
            }
            &:nth-child(2) {
                top: 10px;
                animation: animB .8s ease .4s infinite;
            }
            &:nth-child(3) {
                top: 20px;
                animation: animC 1s ease 1.2s infinite;
            }
        }
        &:hover {
            transform: translate(-50%, -15px);
        }
    }
    @keyframes animA {
        0% {
            color: #c9c9c9;
        }
        100% {
            color: rgba(0, 58, 74, .4);
        }
    }
    @keyframes animB {
        0% {
            color: #c9c9c9;
        }
        100% {
            color: rgba(0, 58, 74, .6);
        }
    }
    @keyframes animC {
        0% {
            color: #c9c9c9;
        }
        100% {
            color: $mainColor;
        }
    }
}
.ex-loving-news-con {
    padding: 50px 20px 88px;
    background-color: #fff;
    .content {
        display: flex;
        margin: 0 -6px 30px;
        li {
            width: calc-percent(1, 4);
        }
        .item {
            display: block;
            margin: 0 6px;
            padding: 20px 20px 35px;
            background-color: #f5f5f5;
            transition: all .2s ease;
            backface-visibility: hidden;
            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                .title {
                    color: $mainColor;
                }
            }
        }
        .img {
            width: 100%;
            padding-bottom: (198/286) * 100%;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .title {
            overflow: hidden;
            font-size: 18px;
            color: #333;
            max-height: 48px;
            line-height: 24px;
            margin-bottom: 15px;
        }
        .p {
            font-size: 14px;
            line-height: 26px;
            color: #888;
            height: 78px;
            overflow: hidden;
        }
    }
    .more {
        display: block;
        margin: 0 auto;
        width: 400px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        color: #fff;
        background-color: $mainColor;
        font-size: 14px;
        text-transform: uppercase;
        transition: all .2s ease;
        &:hover {
            background-color: $hoverColor;
        }
    }
}
.ex-loving-memory-con {
    padding: 144px 20px 88px;
    background-color: #fff;
    background-image: url("../images/ex_article_27.jpg");
    background-position: left top;
    background-repeat: no-repeat;
    .content {
        display: flex;
    }
    .memory-swiper-box {
        position: relative;
        width: 50%;
        padding: 45px 50px 65px 45px;
        background: #fff;
        box-shadow: 0 0 20px 10px rgba(0, 0, 0, .2);
        box-sizing: border-box;
        z-index: 1;
    }
    .memory-container {
        height: 348px;
        padding-left: 18px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 18px;
            width: 2px;
            height: 100%;
            background: $mainColor;
        }
        .swiper-slide {
            height: auto;
            margin-bottom: 20px;
        }
        .info {
            position: relative;
            padding-left: 30px;
            &::after {
                content: '';
                position: absolute;
                left: -6px;
                top: 0;
                width: 14px;
                height: 14px;
                background-image: url("../images/ex_round.png");
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .time {
            margin-bottom: 8px;
            line-height: 1;
            font-size: 18px;
            font-family: "Arial";
            color: $mainColor;
        }
        .p {
            line-height: 24px;
            font-size: 14px;
            color: #777;
        }
    }
    .swiper-btn {
        display: flex;
        position: absolute;
        top: 45px;
        right: 50px;
        .button-prev,
        .button-next {
            width: 40px;
            height: 40px;
            margin: 0 5px;
            line-height: 40px;
            text-align: center;
            background: $mainColor;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            transition: all .2s ease;
            i {
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                color: #fff;
            }
            &:hover {
                background: $hoverColor;
            }
        }
    }
    .memory-form-box {
        position: relative;
        width: 50%;
        padding: 45px 85px 65px;
        background: $mainColor;
        box-sizing: border-box;
        z-index: 2;
    }
    .swiper-container-free-mode > .swiper-wrapper {
        -webkit-transition-timing-function: linear;
        -moz-transition-timing-function: linear;
        -ms-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
    }
}
.loving-article-con {
    padding: 80px 20px;
    background-color: #fff;
}
.loving-article {
    line-height: 30px;
    color: #666;
    @include articleReset(14px, 30px);
    .img {
        float: right;
        margin-left: 80px;
        margin-bottom: 20px;
    }
    .t {
        font-size: 30px;
        color: #333;
        margin-bottom: 20px;
    }
}
.loving-history-con {
    background-color: #f5f5f5;
    padding: 75px 20px 110px;
    .t {
        text-align: center;
        font-size: 30px;
        color: #333;
        margin-bottom: 24px;
    }
}
.loving-history-box {
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}
.loving-history-swiper {
    height: 64px;
    background-color: $mainColor;
    position: relative;
    padding: 0 68px;
    background-image: url(../images/loving_3.png);
    background-position: center bottom;
    background-repeat: repeat-x;
    .swiper-container {
        width: 100%;
    }
    .swiper-wrapper {
        backface-visibility: hidden;
    }
    .swiper-slide {
        height: 64px;
        line-height: 64px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-family: Arial;
        position: relative;
        &:after {
            position: absolute;
            width: 1px;
            height: 12px;
            background-color: #fff;
            left: 50%;
            bottom: 0;
            display: block;
            content: '';
        }
        &.on {
            font-size: 28px;
            font-weight: bold;
        }
    }
    .loving-history-prev,
    .loving-history-next {
        position: absolute;
        top: 50%;
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        color: #4b4b4b;
        background-color: #fff;
        border-radius: 100%;
        margin-top: -13px;
        cursor: pointer;
    }
    .loving-history-prev {
        left: 36px;
    }
    .loving-history-next {
        right: 36px;
    }
}
.loving-history-bd {
    .box {
        display: none;
        padding: 50px 100px;
        line-height: 30px;
        color: #666;
        @include articleReset(14px, 30px);
        h3 {
            font-size: 26px;
            color: #444;
            margin-bottom: 40px;
            line-height: 36px;
            text-align: center;
        }
        &:first-child {
            display: block;
        }
        .more {
            display: block;
            margin: 0 auto;
            width: 300px;
            height: 52px;
            text-align: center;
            line-height: 52px;
            color: #fff;
            background-color: $mainColor;
            font-size: 14px;
            text-transform: uppercase;
            transition: all .2s ease;
            &:hover {
                background-color: $hoverColor;
            }
        }
    }
}
.loving-news-con {
    padding: 70px 20px 90px;
    background-color: #fff;
    .t {
        text-align: center;
        font-size: 30px;
        color: #333;
        margin-bottom: 24px;
    }
    .more {
        display: block;
        margin: 0 auto;
        width: 400px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        color: #fff;
        background-color: $mainColor;
        font-size: 14px;
        text-transform: uppercase;
        transition: all .2s ease;
        &:hover {
            background-color: $hoverColor;
        }
    }
}
.loving-news-list {
    font-size: 0;
    width: (406px + 46px) * 3;
    margin: 0 auto;
    max-width: 100%;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        padding: 0 23px;
        margin: 0 0 17px;
        box-sizing: border-box;
        a {
            display: block;
            padding: 36px;
            background-color: #f5f5f5;
            transition: all .2s ease;
            backface-visibility: hidden;
            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                .title {
                    color: $mainColor;
                }
            }
        }
        .img {
            width: 100%;
            padding-bottom: (232/334) * 100%;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
            color: #333;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .p {
            font-size: 14px;
            line-height: 26px;
            color: #888;
            height: 78px;
            overflow: hidden;
        }
    }
}
.loving-memory-con {
    padding: 80px 20px 0;
    background-image: url(../images/loving_5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    .t {
        text-align: center;
        font-size: 30px;
        color: #fff;
        margin-bottom: 24px;
    }
}
.loving-memory-box {
    padding: 50px 0 70px;
    background-color: #fff;
    box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
    .icon {
        text-align: center;
    }
}
.loving-memory-swiper {
    width: 1100px;
    margin: 0 auto;
    height: 450px;
    padding-top: 20px;
    &:before {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: $mainColor;
        content: '';
        display: block;
        margin-left: -1px;
        top: 0;
    }
    .swiper-wrapper {
        z-index: 5;
    }
    .swiper-slide {
        overflow: hidden;
        &:nth-child(2n+1) {
            .info {
                float: left;
                text-align: right;
                padding-right: 30px;
                &:before {
                    right: -7px;
                }
            }
        }
        &:nth-child(2n) {
            .info {
                float: right;
                padding-left: 30px;
                &:before {
                    left: -7px;
                }
            }
        }
        .info {
            width: 50%;
            box-sizing: border-box;
            position: relative;
            &:before {
                position: absolute;
                top: 5px;
                width: 14px;
                height: 14px;
                background-image: url(../images/loving_7.jpg);
                content: '';
                display: block;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        .time {
            font-size: 18px;
            color: $mainColor;
            margin-bottom: 5px;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            height: 48px;
            color: #777;
        }
    }
}
.loving-memory-btn {
    font-size: 0;
    text-align: center;
    > div {
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        background-color: $mainColor;
        border-radius: 100%;
        margin: 0 2px;
        outline: none;
    }
}
.loving-form-con {
    padding: 70px 20px;
    background-color: #fff;
    .t {
        text-align: center;
        font-size: 30px;
        color: #333;
        margin-bottom: 24px;
    }
    .box {
        background-color: #f5f5f5;
        padding: 70px 0 80px;
    }
    .form {
        width: 1062px;
    }
}
.contact-wrap {
    padding-bottom: 125px;
    background: #f0f0f0;
    .contact-con {
        position: relative;
        padding: 70px 30px 30px;
        margin-top: -175px;
        background: #fff;
        z-index: 2;
    }
    .hd {
        display: flex;
        flex-wrap: wrap;
        padding: 0 150px;
        .item {
            display: flex;
            align-items: center;
            width: 50%;
            padding-bottom: 65px;
        }
        .icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            border: 1px solid $mainColor;
            border-radius: 50%;
            .img {
                width: 45px;
                height: 45px;
                text-align: center;
            }
            img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .info-box {
            padding-left: 15px;
            font-size: 14px;
            color: #666;
            flex: 1;
            overflow: hidden;
            box-sizing: border-box;
            p {
                margin-bottom: 10px;
            }
        }
        .ewm-box {
            width: 100px;
            height: 100px;
            margin-left: 15px;
            line-height: 100px;
            text-align: center;
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .bd {
        width: 100%;
        > div {
            width: 100%;
            height: 440px;
        }
    }
}
.choose-buy-wrap {
    padding-bottom: 90px;
    background-color: #f5f5f5;
    .choose-bg {
        width: 100%;
        height: 500px;
        background: url("../images/article_06.jpg") center no-repeat;
        background-size: cover;
        .choose-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1536px;
            margin: 0 auto;
            padding-top: 78px;
            padding-left: 40px;
            box-sizing: border-box;
            @media screen and (max-width: 1500px) {
                width: 1300px;
            }
        }
        .bread-list {
            display: flex;
            align-items: center;
            height: 38px;
            font-size: 12px;
            color: #fff;
            .iconfont {
                padding-right: 5px;
                font-size: 24px;
                color: $mainColor;
            }
            a {
                padding: 0 5px;
                font-size: 14px;
                color: #fff;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .back-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 130px;
            height: 38px;
            padding: 0 20px;
            font-size: 14px;
            color: #fff;
            background-color: $mainColor;
            border-radius: 20px;
            box-sizing: border-box;
            @include transition();
            .iconfont {
                font-size: 12px;
            }
            &:hover {
                background-color: $hoverColor;
            }
        }
    }
    .wrapper {
        position: relative;
        width: 1536px;
        padding: 76px 300px 80px;
        margin: -365px auto 0;
        background-color: #fff;
        box-shadow: 1.5px 2.598px 65px 0px rgba(0, 0, 0, .2);
        box-sizing: border-box;
        @media screen and (max-width: 1500px) {
            width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            width: auto;
        }
        .con {
            line-height: 32px;
            font-size: 14px;
            color: #666;
            @include articleReset();
        }
        .self-con {
            display: flex;
        }
        .left {
            width: 583px;
        }
        .right {
            flex: 1;
            width: 0;
            padding-left: 80px;
            overflow: hidden;
        }
        .title {
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: 30px;
            color: #333;
            @media screen and (max-width: 1500px) {
                margin-top: 0;
            }
        }
        p {
            margin-bottom: 15px;
        }
        .tt {
            margin-top: 35px;
            margin-bottom: 15px;
            font-size: 18px;
            color: $mainColor;
        }
    }
}
.join-wrap {
    background-color: #f5f5f5;
    img {
        max-width: 100%;
        height: auto !important;
    }
}
.join-wrap-01 {
    padding: 70px 20px;
    text-align: center;
    .title {
        margin-bottom: 50px;
        line-height: 1;
        .b-t {
            margin-bottom: 5px;
            font-size: 48px;
            color: #333;
        }
        .s-t {
            margin-bottom: 10px;
            font-size: 30px;
            color: #333;
        }
        .en {
            font-size: 30px;
            font-family: "Arial";
            color: #777;
            text-transform: uppercase;
        }
    }
    &.case_list {
        padding: 0 0 70px;
    }
}
.join-wrap-02 {
    padding-bottom: 70px;
    &.case_list {
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}
.join-wrap-03 {
    padding: 0 20px 80px;
    .form-box {
        display: flex;
        .left {
            width: 50%;
            height: 530px;
            padding: 120px;
            background: url("../images/ex_article_14.jpg") center no-repeat;
            background-size: cover;
            box-sizing: border-box;
            .cn {
                margin-bottom: 15px;
                line-height: 1;
                font-size: 48px;
                color: #fff;
            }
            .en {
                width: 250px;
                margin-bottom: 30px;
                line-height: 48px;
                font-size: 55px;
                font-family: "Arial";
                color: #fff;
            }
            .num {
                line-height: 1;
                font-size: 38px;
                color: #fff;
            }
        }
        .right {
            flex: 1;
            padding: 45px 50px;
            background-color: #fff;
            overflow: hidden;
        }
    }
    .map-box {
        padding-top: 65px;
        .title {
            margin-bottom: 30px;
            text-align: center;
            .cn {
                margin-bottom: 10px;
                line-height: 1;
                font-size: 36px;
                color: #333;
            }
            .en {
                line-height: 1;
                font-size: 30px;
                font-family: "Arial";
                color: #777;
                text-transform: uppercase;
            }
        }
    }
    .select-address {
        display: flex;
        width: 600px;
        margin: 0 auto;
        .box {
            display: flex;
            flex: 1;
            background: #fff;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
        .select {
            flex: 1;
            padding: 0 15px;
            overflow: hidden;
        }
        select {
            width: 100%;
            height: 45px;
            line-height: 45px;
            font-size: 12px;
            color: #4f4f4f;
            border: none;
            background: none;
            outline: none;
            cursor: pointer;
        }
        .input {
            flex: 1;
            padding: 0 15px;
            overflow: hidden;
            input {
                width: 100%;
                height: 45px;
                line-height: 45px;
                font-size: 12px;
                color: #4f4f4f;
                background: none;
                border: none;
            }
        }
        .search-btn {
            width: 45px;
            height: 45px;
            background: $mainColor;
            border: none;
            cursor: pointer;
            @include transition();
            &:hover {
                background: $hoverColor;
            }
            .iconfont {
                font-size: 18px;
                color: #fff;
            }
        }
    }
    .hot-city {
        display: flex;
        align-items: center;
        padding: 40px 0;
        margin: 0 230px;
        .hot-tt {
            line-height: 1;
            font-size: 26px;
            color: #444;
        }
        .citys-box {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding-left: 10px;
            overflow: hidden;
            a {
                display: block;
                height: 25px;
                padding: 0 12px;
                margin: 5px;
                line-height: 25px;
                background: #f9f9f9;
                border: 1px solid #e5e5e5;
                border-radius: 3px;
                @include transition();
                &.on,
                &:hover {
                    background: $mainColor;
                    color: #fff;
                }
            }
        }
    }
    .store-address {
        display: flex;
        height: 610px;
        overflow: hidden;
        .store-swiper {
            position: relative;
            width: 360px;
            height: 100%;
            padding-right: 10px;
            box-sizing: border-box;
            .swiper-container {
                height: 100%;
            }
            .swiper-scroller {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 10px;
                background: #e2e2e2;
                z-index: 9;
            }
            .swiper-scrollbar-drag {
                width: 100%;
                background: #64717d;
                border-radius: 0;
            }
        }
        .swiper-slide {
            height: auto;
            padding: 36px 36px 20px 50px;
            background: #254857;
            box-sizing: border-box;
            &:nth-child(odd) {
                background: $mainColor;
            }
            .tt {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            .icon {
                width: 15px;
                height: 20px;
                padding-right: 5px;
                line-height: 20px;
                text-align: center;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .word {
                font-size: 18px;
                color: #fff;
            }
            .p {
                padding-left: 20px;
                line-height: 20px;
                font-size: 14px;
                color: #a2b9c1;
            }
            .btn {
                display: block;
                width: 90px;
                height: 30px;
                margin-top: 18px;
                margin-left: 20px;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
                color: $mainColor;
                background: #fff;
            }
            &.on {
                background: $hoverColor;
            }
        }
        .map {
            flex: 1;
            overflow: hidden;
            > div {
                width: 100%;
                height: 100%;
            }
        }
    }
}