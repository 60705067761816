.index-banner-wrap {
    width: 100%;
    .swiper-slide {
        display: block;
    }
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }
    .swiper-pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        right: 25px;
        bottom: auto;
        left: auto;
        width: 18px;
        height: 100%;
    }
    .my-bullet {
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
        margin: 5px 0;
        border: 1px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
        @include transition();
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 50%;
        }
    }
    .my-bullet-active {
        border-color: #fff;
    }
}
.index-title {
    line-height: 1;
    text-align: center;
    color: #000;
    .cn {
        margin-bottom: 20px;
        font-size: 36px;
        font-family: "Source Han Sans CN";
    }
    .en {
        font-size: 32px;
        font-family: "Arial";
        text-transform: uppercase;
    }
    &.about {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
    }
    &.insp {
        color: #fff;
    }
}
.index-product-wrap {
    padding: 110px 0 150px;
    background-color: #fff;
    background-image: url("../images/ex_index_1.jpg");
    background-position: left bottom;
    background-repeat: no-repeat;
    .content-box {
        display: flex;
        padding: 70px 50px 108px;
        background-color: #fff;
        box-sizing: border-box;
    }
    .list {
        width: calc-percent(1, 3);
        padding: 0 20px;
        box-sizing: border-box;
    }
    .item {
        display: block;
        width: 100%;
        .img {
            position: relative;
            height: 0;
            padding-top: (260/400) * 100%;
            backface-visibility: hidden;
            overflow: hidden;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include transition(.4s);
        }
        .tt {
            padding: 20px 20px 0;
            text-align: center;
            line-height: 1;
            font-size: 16px;
            color: #333;
        }
        .cn {
            margin-bottom: 10px;
        }
        .en {
            font-family: "Segoe UI Emoji";
            text-transform: uppercase;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
            .tt {
                font-weight: 600;
                color: $mainColor;
            }
        }
    }
}
.index-about-wrap {
    position: relative;
    padding: 0 20px;
    background-color: #f5f5f5;
    .content-box {
        display: flex;
    }
    .list {
        width: calc-percent(1, 4);
        padding: 230px 0 90px;
        background-color: #f9f9f9;
        box-sizing: border-box;
        &:nth-child(odd) {
            background-color: #fff;
        }
    }
    .item {
        display: block;
        padding: 0 30px;
        &:hover {
            .img img {
                transform: scale(1.05);
            }
            .info {
                .tt {
                    color: $mainColor;
                }
                .icon {
                    background-color: $mainColor;
                    border-color: $mainColor;
                    .iconfont {
                        color: #fff;
                    }
                }
            }
        }
    }
    .img {
        position: relative;
        height: 0;
        padding-top: (215/297) * 100%;
        backface-visibility: hidden;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .4s ease;
        }
    }
    .info {
        padding-top: 20px;
        text-align: center;
        .tt {
            margin-bottom: 20px;
            line-height: 1;
            font-size: 18px;
            color: #000;
            transition: all .4s ease;
        }
        .msg {
            height: 56px;
            line-height: 28px;
            font-size: 14px;
            color: #666;
            overflow: hidden;
        }
        .icon {
            display: inline-block;
            vertical-align: top;
            width: 34px;
            height: 34px;
            margin-top: 30px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #bebebe;
            border-radius: 50%;
            transition: all .4s ease;
            .iconfont {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                font-size: 14px;
                color: #999;
                transition: all .4s ease;
            }
        }
    }
}
.index-insp-wrap {
    padding: 90px 20px 140px;
    background-image: url("../images/ex_index_9.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .insp-swiper-con {
        position: relative;
        margin-top: 35px;
        padding: 0 94px;
        .swiper-slide {
            height: 0;
            padding-top: (380/1350) * 100%;
            backface-visibility: hidden;
            overflow: hidden;
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                transition: all .4s ease;
            }
            .info {
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                bottom: 30px;
                width: 100%;
                padding-left: 60px;
                padding-right: 30px;
                box-sizing: border-box;
                z-index: 2;
            }
            .tt {
                flex: 1;
                font-size: 16px;
                color: #fff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                transition: all .4s ease;
            }
            .data {
                font-size: 0;
            }
            .iconfont,
            .num {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                color: #fff;
            }
            .num {
                padding-left: 5px;
                font-size: 12px;
            }
        }
        .button-prev,
        .button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 65px;
            outline: none;
            cursor: pointer;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .button-prev {
            left: 0;
        }
        .button-next {
            right: 0;
        }
    }
}